/* For WebKit browsers (Chrome, Safari) */
::-webkit-scrollbar {
    width: 4px; /* Adjust the width of the scrollbar */
  }
  
  ::-webkit-scrollbar-track {
    background: #f1f1f1; /* Background color of the scrollbar track */
  }
  
  ::-webkit-scrollbar-thumb {
    background: #888; /* Color of the scroll thumb */
    border-radius: 6px; /* Rounded corners of the scroll thumb */
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #555; /* Color of the scroll thumb on hover */
  }
  
  /* For Firefox */
  .scrollable {
    scrollbar-width: thin; /* Sets the scrollbar to a thinner size */
    /*scrollbar-color: #888 #f1f1f1;*/ /* Thumb color and track color */
  }
  
  /*display none for pdf file */
  #rpv-default-layout__sidebar-tab-1,  #rpv-default-layout__sidebar-tab-2, .rpv-open__input-wrapper{
display: none;
  }

@media (min-width: 1024px) {
    .rpv-default-layout__sidebar--opened {
        background: inherit;
        position: inherit;
        width: 12rem !important;
        z-index: inherit;
    }
}
  /* .rpv-thumbnail__items {
    justify-content: left !important;
} */
  
/* //Custom CSS to remove extra spacing from the sidebar */
/* .rpv-default-layout__sidebar {
  padding: 0 !important;
  margin: 0 !important;
  width: auto; 
  overflow: hidden; 
}

/* Custom CSS to adjust the sidebar when thumbnail UI is showing */
/* .custom-layout .custom-sidebar {
  padding: 0 !important;
  margin: 0 !important;
  display: none; 
} */

/* Ensure the main viewer area takes up available space */
/*.custom-layout .custom-viewer {
  flex: 1;
  overflow: hidden;
} */

/* loading icon spinner*/
.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh; /* Adjust the height as needed */
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border-left-color: #09f;
  animation: spin 1s ease infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
  